
<template>
  <div>
    <v-card style="border-radius: 10px" flat>
      <v-card-title primary-title class='primary white--text py-3 px-0'>
        <div class="pl-5" style="word-break: break-word">Formulários não respondidos</div>
      </v-card-title>
      <v-card-text class="px-3 pb-6">
        <card-loading-transparent v-if="loading"></card-loading-transparent>
        <div v-if="!loading && items.length <= 0" class="grey--text title mt-6 mb-n1" style="text-align: center">
          Todos os formulários até o momento foram preenchidos.
        </div>
        <v-data-iterator  
          hide-default-footer 
          :items="items" 
          :items-per-page.sync="itemsPerPage" 
          :page.sync="page"
          :sort-by="sortBy.toLowerCase()" 
          :sort-desc="sortDesc" 
          v-if="!loading && items.length > 0" 
        >
          <template v-slot:default="props">

            <v-row v-for="item in props.items" :key="item.idx" class="mx-0">
              <v-col cols="12" class="pa-0">
                <default-certificate-card :item="item" @itemId="handleItemId"/>
              </v-col>
            </v-row>

          </template>
          <template v-slot:footer>
            <v-row class="mt-5 px-6" align="center" justify="center" v-if="1 < numberOfPages">
              <span class="grey--text">Itens por página</span>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark text color="primary" class="ml-2" v-bind="attrs" v-on="on" >
                    {{ itemsPerPage }}
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(number, index) in itemsPerPageArray" :key="index" @click="updateItemsPerPage(number)">
                    <v-list-item-title>{{ number }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-spacer></v-spacer>
              <span class="mr-4 grey--text">
                Pagina {{ page }} de {{ numberOfPages }}
              </span>
              <div>
                <v-btn fab dark color="blue darken-3" x-small class="mr-1 elevation-0" @click="formerPage">
                  <v-icon size='22'>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn fab dark color="blue darken-3" x-small class="ml-1 elevation-0" @click="nextPage">
                  <v-icon size='22'>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </v-row>
          </template>
        </v-data-iterator>
      </v-card-text>
      <v-card-actions class="justify-end">
      </v-card-actions>
    </v-card>
    
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import Api from '@/api/index';

export default {
  name: 'UnansweredForms',
  components: {
    UnansweredForms: () => import('../unanswered/UnansweredForms.vue'),
    FormList: () => import ('../list/FormList.vue'),
    DefaultCertificateCard: () => import('../shared/DefaultCertificateCard.vue'),
  },
  props: {
    user_id: {
      type: Number,
      defalt: null
    }
  },
  data() {
    return {
      itemsPerPageArray: 2,
      search: '',
      sortDesc: false,
      page: 1,
      itemsPerPage: 4,
      sortBy: 'start_date',
      loading: false,
      items: []
    }
  },
  mounted () {
    this.getCourses()
  },
  methods: {
    handleItemId(id){
      this.certificateDialog(id)
    },
    certificateDialog(course_id){
      return this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Preencher',
        title: 'Deseja preencher o formulário de avaliação agora ?',
        icon: 'question',
      }).then((params)=>{
        params.isConfirmed == true ? this.$router.push({name: 'CourseRating', params:{course_id: course_id}}) : ''
      })
    },
    getCourses(){
      this.loading = true
      Api.MyCourse.index({courses: 'not_rated'}).then(r => r.data).then(r => {
        this.setItems(r)
      }).catch(error => {
        console.log(error)
      })
    },
    setItems(items){
      setTimeout(()=>{
        this.items = items
        this.loading = false
      },1200)
    },
    nextPage () {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage () {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage (number) {
      this.itemsPerPage = number
    },
    ...mapActions({
    })
  },
  computed: {
    numberOfPages () {
      return Math.ceil(this.items.length / this.itemsPerPage)
    },
    ...mapState({
      user: state=> state.User.user,
    }),
    ...mapState({
      user: state=> state.User.user,
    })
  },
}
</script>

<style>
</style>